import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import becary from '../assets/becary.png';
import irud from '../assets/irud.png';
import jpntravel from '../assets/jpntravel.png';
import giftforlife from '../assets/giftforlife.png';
import contrast from '../assets/contrast.png';

export default function Home() {
  const images = [irud, contrast, giftforlife, jpntravel, becary];
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="home-body-container">
      <div className="homepage-title">
        <h3>WEB DEVELOPMENT / DESIGN</h3>
        <h1>SARI<br/> ELLILÄ</h1>
        <h2>
          Professional experience with<br /> multiple
          web applications using<br/> <strong>JavaScript</strong> and <strong>React</strong>,
          and design  <br />using Adobe programs<br/> and Figma.<br />
          <Link to="./about"><strong>More about me...</strong></Link>
        </h2>
      </div>

      <div className="homepage-img">
        {images.map((img, index) => (
          <img
            key={index}
            src={img}
            alt={`Image ${index}`}
            className={index === activeIndex ? 'active' : ''}
          />
        ))}
      </div>
    </div>
  );
}