import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import becary from '../assets/becary.png';
import irud from '../assets/irud.png';
import jpntravel from '../assets/jpntravel.png';
import giftforlife from '../assets/giftforlife.png';
import contrast from '../assets/contrast.png';
import HorrorDBScreenGrab from '../assets/HorrorDBScreenGrab.mp4';

export default function Work() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const projects = [
    {
      title: "Becary (FREELANCE)",
      subtitle: "DEVELOPMENT & COLLABORATED DESIGN",
      skills: "SKILLS USED: Figma, HTML, CSS, Javascript, React, Firebase",
      description: "Working closely with start-up Becary creating a design and website structure easy to navigate and easy on the eye. Includes a lot of complex functionality such as user profiles and booking calendars. Information is stored in Google’s Firestore - backend.",
      image: becary,
      website: "http://satisfying-cushion.surge.sh",
      code: "https://gitfront.io/r/sarisusanna/muipD68iyuKq/becary-app/",
    },
    {
      title: "Iru-D-Studio (FREELANCE)",
      subtitle: "DEVELOPMENT & COLLABORATED DESIGN",
      skills: "SKILLS USED: Figma, HTML, CSS, Javascript, React",
      description: "Working with Iru-D-Studio creating a more stylish and dynamic page for them. Special points in the project are the CSS and animation aspects.",
      image: irud,
      website: "http://utopian-babies.surge.sh",
      code: "http://utopian-babies.surge.sh",
    },
    {
      title: "Contrast Club (Luxury Jewelry Brand)",
      subtitle: "DEVELOPMENT",
      skills: "SKILLS USED: HTML, CSS, Javascript, React",
      description: "Following a set design closely to showcase a new collection of the brand. It is a simple project, but I learned about working to deliver a design determined by the customer, and they were pleased with the work.",
      image: contrast,
      website: "http://wandering-nail.surge.sh",
      code: "https://github.com/SariEllila/ContrastClub-React",
    },
    {
      title: "Gift for Life (SCHOOL PROJECT)",
      subtitle: "DESIGN & DEVELOPMENT",
      skills: "SKILLS USED: Figma, HTML, CSS, Javascript, React, Node.js",
      description: "An online store with product information being rendered from a MongoDB Atlas database in the backend. A payment method is being implemented with Stripe.",
      image: giftforlife,
      website: "",
      code: "https://gitfront.io/r/sarisusanna/Hop4Rb67inkL/FirstWebApp/",
    },
    {
      title: "Travel Japan (PERSONAL PROJECT)",
      subtitle: "DESIGN & DEVELOPMENT",
      skills: "SKILLS USED: HTML, CSS, Javascript, React",
      description: "A webpage with information on travel destinations in Japan. It includes a weather API and a quiz to determine the most suitable destination for you.",
      image: jpntravel,
      website: "http://superficial-sense.surge.sh",
      code: "https://github.com/SariEllila/TRAVEL-APP",
    },
    {
      title: "Horror Database (SCHOOL PROJECT)",
      subtitle: "DESIGN & DEVELOPMENT",
      skills: "SKILLS USED: HTML, CSS, Javascript, React Native, Expo",
      description: "A movie database app specified for the horror genre. It uses TMDB Movie API to receive the film information.",
      video: HorrorDBScreenGrab,
      code: "https://gitfront.io/r/sarisusanna/Rcghryp6jSeR/MovieDB/",
    }
  ];

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % projects.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + projects.length) % projects.length);
  };

  const { title, subtitle, skills, description, image, video, website, code } = projects[currentIndex];

  return (
    <div className="homepage-work-container">
<Link to="./home"><button>HOME</button></Link>

      <button onClick={handlePrev} className="arrow left-arrow">←</button>
      
      <div className="work-categories-content">
        <div className="mobile-content-main">
          <div>
            {image && <img src={image} alt={title} />}
            {video && (
              <video autoPlay loop muted className="w-full h-full object-cover">
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
          <div className="mobile-work-categories-main-content">
            <div className="mobile-content-title">
              <h3>{title}</h3>
              <h4>{subtitle}</h4>
              <span className="mobile-work-categories-main-content-skills">
                <h4>{skills}</h4>
              </span>
            </div>
            <div className="mobile-work-categories-buttons">
              {website && (
                <a href={website} target="_blank" rel="noopener noreferrer">
                  <button>VIEW WEBSITE</button>
                </a>
              )}
              <a href={code} target="_blank" rel="noopener noreferrer">
                <button>VIEW CODE</button>
              </a>
            </div>
            <div className="mobile-work-categories-desc">
              <p><strong>Project Description:</strong></p>
              <p>{description}</p>
            </div>
          </div>
        </div>
      </div>
      
      <button onClick={handleNext} className="arrow right-arrow">→</button>
    </div>
  );
}