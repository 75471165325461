import React, { useState } from 'react';
import becary from '../assets/becary.png';
import irud from '../assets/irud.png';
import jpntravel from '../assets/jpntravel.png';
import giftforlife from '../assets/giftforlife.png';
import contrast from '../assets/contrast.png';
import omakuva from '../assets/omakuva.jpg';
import { Link } from 'react-router-dom';

export default function About() {

  const [view, setView] = useState('study'); // Possible values: 'study', 'skills', 'intro'

  const handleOpenSkills = () => {
    setView('skills');
  };

  const handleOpenIntro = () => {
    setView('intro');
  };

  const handleReturnToBG = () => {
    setView('study');
  };

  return (
    <div className="home-body-container">
      <div className="homepage-title">
        <h3>WEB DEVELOPMENT / DESIGN</h3>
        <h1>ABOUT <br/> ME</h1>

        <div className="aboutme-content">

          {view === 'study' && (
            <div className="study-bg-list">
              <ul>
                <li>Helsinki Design School / Graphic Design Degree<br/> ( - Dec 2022)</li>
                <li>Barcelona Code School / Javascript Fullstack Bootcamp<br/> ( - Dec 2023)</li>
                <li>Web design & Development Freelance career<br/> (Jan 2024 -)</li>
              </ul>
              <p className="down-arrow" onClick={handleOpenSkills}>↓</p>
            </div>
          )}

          {view === 'skills' && (
            <div className="aboutme-skills">
              <h2>Skills</h2>
              <h3>HTML / JS / CSS / React / React Native / <br/>Node.js / Figma / Adobe Suite</h3>
              <p className="down-arrow" onClick={handleOpenIntro}>↓</p>
            </div>
          )}

          {view === 'intro' && (
            <div className="aboutme-intro">
              <h2>I'm a hard worker with a varied background of different skills, mainly great communication and organizational skills, with an enjoyment for problem-solving...</h2>
              <p className="down-arrow" onClick={handleReturnToBG}>↑</p>
            </div>
          )}

        </div>


      </div>

      <div>
      <img src={omakuva} alt="omakuva" className='omakuva'/>
      </div>

    </div>
  );
}
// export default function About() {
//     return (
//         <div>
//             <div class="aboutme-title">
//             <h1>Hi! I'm Sari from Helsinki, Finland. I love programming because I love learning new and improving myself. I am a great teamplayer, responsible worker and very open- and positive-minded!</h1>
//             </div>

//             <div class="aboutme-content">
//             <img src={omakuva} alt="omakuva"/>
//                 <div class="aboutme-text">
//                         <h4>Studies and specialization</h4>
//                         <p>I specialize in web applications coded with Javascript and React, but I would gladly learn other languages and libraries as well.
//                         I am a graduate of Barcelona Code School's <strong>Fullstack Javascript Bootcamp</strong> and have a degree in <strong>Graphic Design</strong> from Helsinki Design School.</p>
//                         <h4>Work Experience</h4>
//                         <p>I have experience with multiple professional projects creating web applications. Currently I work with two companies creating website reneweals for them, writing all of the code and in most cases the design myself. I have also worked with UX / UI design projects creating data management and user profile - systems.</p>
//                         <div class="aboutme-linkedin-text">
//                         <p>Please read more about my background on my <a href="https://www.linkedin.com/in/sari-susanna-ellilä-29a0a9189/" target="_blank" alt="" rel="noreferrer"><span class="aboutme-linkedin">Linkedin</span></a> page</p>
//                         <Link to="/home"><button>HOME</button></Link>
//                         </div>
//                 </div>
//             </div>

//         </div>
//     )
// }