import React from 'react';

export default function Contact() {

    return (
        <div className="contact-container" id="contact-container">
            <h1>CONTACT</h1>
            <div className="contact-divider"></div>
            <div className="contact-info">
                <h4>
                <span className="email">sari.ellila@gmail.com</span>
                </h4>
                <h4><a href="https://www.linkedin.com/in/sari-susanna-ellilä-29a0a9189/" target="_blank" alt="" rel="noreferrer">LinkedIn</a></h4>
            </div>
        </div>
    );
}